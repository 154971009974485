import React, { useEffect } from "react";
import useActiveAgentAuth from "../../Api/useActiveAgentAuth";
import LoadingAnimation from "../LoadingAnimation/Index";
import useActiveAdAccounts from "../../Api/useActiveAgentAdAccounts";

export default function ActiveAgentLogin() {
  const aaAuth = useActiveAgentAuth();
  const { updateAdAccounts } = useActiveAdAccounts();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasCredentials, setHasCredentials] = React.useState(false);

  useEffect(() => {
    async function getCredentials() {
      setIsLoading(true);
      const credentials = await aaAuth.getCredentials();
      setIsLoading(false);
      if (credentials.success) {
        setUsername(credentials.data.username);
        setPassword(credentials.data.password);
        setHasCredentials(true);
      }
    }
    getCredentials();
  }
  , []);

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            {
              isLoading && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
                  <div className="bg-black1 p-4 rounded-md">
                    <div className="flex items-center justify-center">
                      {/* <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green"></div> */}
                      <LoadingAnimation />
                    </div>
                    <div className="text-center text-white mt-2">Loading...</div>
                  </div>
                </div>
              )
            }
            <div>
              <label htmlFor="activeagent-email" className="block text-sm font-medium leading-6 text-white">
                Username
              </label>
              <div className="mt-2">
                <input
                  id="activeagent-email"
                  name="activeagent-email"
                  type="email"
                  autoComplete="activeagent-email"
                  value={username}
                  required
                  onChange={(e) => setUsername(e.target.value)}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:green sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="activeagent-password" className="block text-sm font-medium leading-6 text-white">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="activeagent-password"
                  name="activeagent-password"
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <button
                  onClick={() => {
                    setIsLoading(true);
                    aaAuth.deleteCredentials().then(r => {
                      setIsLoading(false);
                      if (r.success) {
                        setUsername("");
                        setPassword("");
                        setHasCredentials(false);
                      }
                    });
                  }}
                  disabled={!hasCredentials}
                  className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm ${
                    hasCredentials ? "font-semibold hover:bg-red-800 bg-red-700" : "font-normal bg-gray-500"
                  } font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red`}
                >
                  Delete Credentials
              </button>
              <button
                onClick={() => {
                  setIsLoading(true);
                  aaAuth.storeCredentials(username, password).then(r => {
                      updateAdAccounts().then((res) => {
                        setIsLoading(false)
                        setHasCredentials(true);
                    });
                });
                }}
                disabled={hasCredentials}
                className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm ${
                  hasCredentials ? "font-normal bg-gray-500" : "font-semibold hover:bg-green1 bg-green"
                } font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green`}
              >
                Save Credentials
              </button>
            </div>
          </div>
        </div>
  )
}