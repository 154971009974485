import { useAuth } from '../Hooks/useAuth';
import { n2api } from './n2api';

const useActiveAdAccounts = () => {

  const { token } = useAuth();

  const updateAdAccounts = async () => {
    return n2api.put(`/activeagent/adaccounts`, undefined, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    })
    .then((response) => {
      console.log('Ad accounts synced successfully');
      if (response.status === 200) {
          return {
              success: true,
              data: response.data
          }
      } else {
          return {
              success: false,
              response: response,
              error: new Error(response.statusText)
          }
      }
    })
    .catch((error) => {
      console.error('Error syncing ad accounts:', error);
      return {
          success: false,
          response: {},
          error: error
      }
    });
  }

  return {
    updateAdAccounts,
  }
}

export default useActiveAdAccounts;